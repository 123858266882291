const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
};

const getInitials = (name) => {
    if (!name) {
        return "";
    }
    return name
        .split(" ")
        .map((n) => n[0])
        .join("")
        .toUpperCase();
};

export {
    truncateText,
    getInitials
};