import { useRef, useCallback } from "react";

const UseDragScroll = () => {
    const ref = useRef(null);
    const mouseDown = useRef(false);
    const startX = useRef(0);
    const scrollLeft = useRef(0);

    const onMouseDown = useCallback((e) => {
        mouseDown.current = true;
        startX.current = e.pageX - ref.current.offsetLeft;
        scrollLeft.current = ref.current.scrollLeft;
    }, []);

    const onMouseLeave = useCallback(() => {
        mouseDown.current = false;
    }, []);

    const onMouseUp = useCallback(() => {
        mouseDown.current = false;
    }, []);

    const onMouseMove = useCallback((e) => {
        if (!mouseDown.current) return;
        e.preventDefault();
        const x = e.pageX - ref.current.offsetLeft;
        const walk = (x - startX.current) * 2;
        ref.current.scrollLeft = scrollLeft.current - walk;
    }, []);

    return {
        ref,
        onMouseDown,
        onMouseLeave,
        onMouseUp,
        onMouseMove,
    };
};

export default UseDragScroll;
