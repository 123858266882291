import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Cookies from "js-cookie";

import {
    Avatar,
    Button,
    CssBaseline,
    TextField,
    FormControlLabel,
    Checkbox,
    Grid,
    Box,
    Snackbar,
    Typography,
    Container
} from "@mui/material";

import NavBar from "../../components/NavBar";

import { updateUserInfo } from "../../service/auth";

// Update SignUp function
export default function UserInfo() {
    const navigate = useNavigate();
    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    // const [profile, setProfile] = useState("");
    const [allowPromotion, setAllowPromotion] = useState(false);
    const [updateFailed, setUpdateFailed] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    useEffect(() => {
        const user_info = Cookies.get("user_info");
        if (user_info !== undefined) {
            const userInfoObject = JSON.parse(user_info);
            localStorage.setItem("id", userInfoObject.id);
            localStorage.setItem("username", userInfoObject.username);
            localStorage.setItem("firstName", userInfoObject.first_name);
            localStorage.setItem("lastName", userInfoObject.last_name);
            localStorage.setItem("email", userInfoObject.email);
            localStorage.setItem("profile", userInfoObject.profile);
            localStorage.setItem("accessToken", userInfoObject.access_token);
            localStorage.setItem("refreshToken", userInfoObject.refresh_token);
            Cookies.remove("user_info");
        }
        if (localStorage.getItem("username") !== null) {
            setUserName(localStorage.getItem("username"));
        }
        if (localStorage.getItem("email") !== null) {
            setEmail(localStorage.getItem("email"));
        }
        if (localStorage.getItem("firstName") !== null) {
            setFirstName(localStorage.getItem("firstName"));
        }
        if (localStorage.getItem("lastName") !== null) {
            setLastName(localStorage.getItem("lastName"));
        }
        if (localStorage.getItem("allowPromotion") !== null) {
            setAllowPromotion(localStorage.getItem("allowPromotion"));
        }
    }, []);

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    const handleSuccess = (resp) => {
        if (
            resp.data.access_token === undefined ||
            resp.data.refresh_token === undefined
        ) {
            console.error("Access token or refresh token is undefined");
            return;
        }
        
        // Save tokens to local storage
        localStorage.setItem("id", resp.data.id);
        localStorage.setItem("username", resp.data.username);
        localStorage.setItem("firstName", resp.data.first_name);
        localStorage.setItem("lastName", resp.data.last_name);
        localStorage.setItem("email", resp.data.email);
        localStorage.setItem("profile", resp.data.profile);
        localStorage.setItem("accessToken", resp.data.access_token);
        localStorage.setItem("refreshToken", resp.data.refresh_token);

        setTimeout(() => {
            navigate(-1);
        }, 2000); // Change the duration (in milliseconds) as needed
    };    

    // Update handleSubmit function
    const handleUpdateUserInfo = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        updateUserInfo(
            data.get("username"),
            data.get("firstName"),
            data.get("lastName"),
            data.get("email"),
            data.get("allowPromotion")
        )
            .then((resp) => {
                if (resp && resp.status === 200) {
                    setOpenSnackbar(true);
                    handleSuccess(resp);
                } else {
                    // Show error message based on response
                    console.error(`Update user info failed: ${resp}`);
                    setUpdateFailed(true);
                }
            })
            .catch((err) => {
                console.error(err);
                setUpdateFailed(true);
            });
    };

    return (
        <Fragment>
            <NavBar />
            <Container component="main" maxWidth="xs">
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={openSnackbar}
                    autoHideDuration={3000}
                    onClose={handleCloseSnackbar}
                    message="Update successful!"
                />
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: "secondary.main" }} />
                    <Typography component="h1" variant="h5">
                        Information
                    </Typography>
                    <Box
                        component="form"
                        noValidate
                        onSubmit={handleUpdateUserInfo}
                        sx={{ mt: 3 }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="username"
                                    label="Username"
                                    name="username"
                                    value={userName}
                                    onChange={(event) =>
                                        setUserName(event.target.value)
                                    }
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="given-name"
                                    name="firstName"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="First Name"
                                    value={firstName}
                                    onChange={(event) =>
                                        setFirstName(event.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="lastName"
                                    label="Last Name"
                                    name="lastName"
                                    autoComplete="family-name"
                                    value={lastName}
                                    onChange={(event) =>
                                        setLastName(event.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    value={email}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="allowPromotion"
                                            name="allowPromotion"
                                            value={allowPromotion}
                                            checked={allowPromotion}
                                            color="primary"
                                            onChange={(event) =>
                                                setAllowPromotion(
                                                    !allowPromotion
                                                )
                                            }
                                        />
                                    }
                                    label="I want to receive inspiration, marketing promotions and updates via email."
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Update
                        </Button>
                        {updateFailed && (
                            <p style={{ color: "red" }}>
                                Update failed. Please try again.
                            </p>
                        )}
                    </Box>
                </Box>
            </Container>
        </Fragment>
    );
}
