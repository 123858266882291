import React, { useState, useEffect } from 'react';
import Editor, { useMonaco } from "@monaco-editor/react";
import { Button } from '@mui/material';

import Block from "./Block";

const MONACO_OPTIONS = {
  autoIndent: "full",
  automaticLayout: true,
  contextmenu: true,
  fontFamily: "'Inter', sans-serif",
  fontSize: 14, // Change the font size here
  lineHeight: 24,
  hideCursorInOverviewRuler: true,
  matchBrackets: "always",
  minimap: {
      enabled: false,
  },
  readOnly: false,
  scrollbar: {
      horizontalSliderSize: 4,
      verticalSliderSize: 18,
  },
};

export default function Codepad() {
  const monaco = useMonaco();
  const [code, setCode] = useState(Block.toString());

  useEffect(() => {
    // do conditional chaining
    monaco?.languages.typescript.javascriptDefaults.setEagerModelSync(true);
    // or make sure that it exists by other ways
    if (monaco) {
      console.log("here is the monaco instance:", monaco);
    }
  }, [monaco]);

  const handleEditorValidation = (markers) => {
    // model markers
    markers.forEach(marker => console.log('onValidate:', marker.message));
  };

  const executeCode = () => {
    try {
      const updatedCode = `
        import React from "react";
        import * as THREE from "three";
        import { Extrude } from "@react-three/drei";
        
        ${code}
      `;
      const updatedBlock = eval(updatedCode);
      setCode(updatedCode);
      Block = updatedBlock;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ width: "100%" }}>
        <Editor
          width="100%"
          height="50vh"
          defaultLanguage="javascript"
          theme="vs-dark"
          value={code}
          onValidate={handleEditorValidation}
          options={MONACO_OPTIONS}
          onChange={(value) => setCode(value)}
        />
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={executeCode}
        style={{
          animation: "color-change 0.3s linear",
          animationFillMode: "forwards",
          alignSelf: "flex-start",
          marginLeft: "16px"
        }}
      >
        Execute
      </Button>
      <style>
        {`
          @keyframes color-change {
            0% {
              background-color: #1976d2;
            }
            100% {
              background-color: #4caf50;
            }
          }
        `}
      </style>
    </div>
  );
}
