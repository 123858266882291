import { HubApiClient } from "./api";

const getMarketplaceList = async () => {
    try {
        const response = await HubApiClient.get("/marketplace/list");
        return response;
    } catch (error) {
        console.log(Object.keys(error), error.message);
    }
}

const getMarketplaceTrendingApps = async () => {
    try {
        const response = await HubApiClient.get("/marketplace/trending");
        return response;
    } catch (error) {
        console.log(Object.keys(error), error.message);
    }
}

const getMarketplaceFeaturedApps = async () => {
    try {
        const response = await HubApiClient.get("/marketplace/featured");
        return response;
    } catch (error) {
        console.log(Object.keys(error), error.message);
    }
}

export { getMarketplaceList, getMarketplaceTrendingApps, getMarketplaceFeaturedApps };
