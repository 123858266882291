import { HubApiClient, API_BASE_URL } from "./api";

const validateToken = async (token) => {
    const validateTokenData = {
        token: token,
    };
    try {
        const response = await HubApiClient.post(
            "/validate-token",
            validateTokenData
        );
        return response;
    } catch (error) {
        console.log(Object.keys(error), error.message);
    }
};

const resetPassword = async (email, newPassword, token) => {
    const resetPasswordData = {
        email: email,
        password: newPassword,
        token: token,
    };
    try {
        const response = await HubApiClient.post(
            "/reset-password",
            resetPasswordData
        );
        return response;
    } catch (error) {
        console.log(Object.keys(error), error.message);
    }
};

const forgotPassword = async (email) => {
    const forgotPasswordData = {
        email: email,
    };
    try {
        const response = await HubApiClient.post(
            "/forgot-password",
            forgotPasswordData
        );
        return response;
    } catch (error) {
        console.log(Object.keys(error), error.message);
    }
};

const login = async (loginInfo, password) => {
    const loginData = {
        login_info: loginInfo,
        password: password,
    };
    try {
        const response = await HubApiClient.post("/login", loginData);
        return response;
    } catch (error) {
        console.log(Object.keys(error), error.message);
    }
};

const logout = async () => {
    try {
        // Set the Authorization header with the access token
        HubApiClient.defaults.headers["Authorization"] = `Bearer ${localStorage.getItem("accessToken")}`;

        // Set the X-Refresh-Token header with the refresh token
        HubApiClient.defaults.headers["X-Refresh-Token"] = localStorage.getItem("refreshToken");

        const response = await HubApiClient.post("/logout");
        if (response.status >= 200 && response.status < 300) {
            delete HubApiClient.defaults.headers["Authorization"];
            delete HubApiClient.defaults.headers["X-Refresh-Token"];

            localStorage.removeItem("id");
            localStorage.removeItem("username");
            localStorage.removeItem("firstName");
            localStorage.removeItem("lastName");
            localStorage.removeItem("email");
            localStorage.removeItem("profile");
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");    
        }
        return response;
    } catch (error) {
        console.log(Object.keys(error), error.message);
    }
};

const signup = async (username, password, email, first_name, last_name, profile) => {
    const signupData = {
        username: username,
        password: password,
        first_name: first_name,
        last_name: last_name,
        email: email,
        profile: profile,
    };

    try {
        const resp = await HubApiClient.post("/signup", signupData);
        return resp;
    } catch (error) {
        console.log(Object.keys(error), error.message);
    }
};

const updateUserInfo = async (
    username,
    firstName,
    lastName,
    email,
    allowPromotion
) => {
    const userInfoData = {
        username: username,
        first_name: firstName,
        last_name: lastName,
        email: email,
        allow_promotion: allowPromotion && Boolean(allowPromotion),
    };

    try {
        const resp = await HubApiClient.put("/user/update", userInfoData);
        return resp;
    } catch (error) {
        console.log(Object.keys(error), error.message);
    }
};

const oauth = (from) => {
    const rootUrl = `https://accounts.google.com/o/oauth2/v2/auth`;

    const options = {
        redirect_uri: API_BASE_URL + "/alpha/google/callback",
        client_id:
            "151828586290-64dmb2j12lo9lq6gu355aq2n0p80frac.apps.googleusercontent.com",
        access_type: "offline",
        response_type: "code",
        prompt: "consent",
        scope: [
            "https://www.googleapis.com/auth/userinfo.profile",
            "https://www.googleapis.com/auth/userinfo.email",
        ].join(" "),
        state: from,
    };

    const qs = new URLSearchParams(options);

    return `${rootUrl}?${qs.toString()}`;
};

export { validateToken, updateUserInfo, forgotPassword, resetPassword, login, logout, signup, oauth };
