import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Card,
    CardMedia,
    Typography,
    CardActionArea,
} from "@mui/material";

import { getRandomImageUrl } from "../utils/RandomImage";
import { truncateText } from "../utils/CardUtils";

const styles = {
    container: {
      paddingTop: "30px", 
      paddingBottom: "30px"
    },
    title: {
      fontWeight: 600, 
      marginBottom: "30px" 
    },
    card: {
      borderRadius: 12,
      boxShadow:
        "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
      margin: "20px",
      width: "200px",
      display: "flex", 
      flexDirection: "column",
    },
    cardMedia: {
      paddingTop: "56.25%",
    },
    titleOverlay: {
      position: "absolute",
      bottom: "10px",
      left: "10px",
      color: "white",
      textShadow: "1px 1px 3px rgba(0,0,0,0.8)",
    },
    description: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      margin: "10px",
    },
    cardActionArea: {
      position: "relative",
    },
    hoverDescription: {
      opacity: 0,
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      padding: "10px",
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      color: "white",
      textAlign: "left",
      transition: "opacity 0.3s",
    },
    cardContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
    },
}


const ProjectCard = ({ project, handleClickOpen }) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  const handleOnClick = (project) => {
    if (handleClickOpen) {
      handleClickOpen(project);
      return;
    }
    navigate("/builder");
  };

  return (
    <Card
      style={styles.card}
      key={project.name}
      sx={{ maxWidth: 345, marginTop: "50px" }}
    >
      <CardActionArea
        onClick={() => handleOnClick(project)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={styles.cardActionArea}
      >
        <CardMedia
          component="div"
          style={styles.cardMedia}
          image={project.snapshot ? project.snapshot : getRandomImageUrl()}
          alt={project.name}
          sx={{
            backgroundImage: `url(${
              project.snapshot ? project.snapshot : getRandomImageUrl()
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: 140,
          }}
        >
          <Typography
            variant="h5"
            component="div"
            style={styles.titleOverlay}
          >
            {project.name}
          </Typography>
        </CardMedia>
        <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              ...styles.hoverDescription,
              opacity: isHovered ? 1 : 0,
            }}
        >
          {truncateText(project.description, 200)}
        </Typography>
      </CardActionArea>
    </Card>
  );
};

export default ProjectCard;