import React from "react";
import Divider from "@mui/material/Divider";

const styles = {
    divider: {
      marginTop: "30px",
      marginBottom: "30px",
      borderColor: "#ffffff",
      opacity: 0.5,
    },
}

export default function DynoDivider() {
    return (
        <Divider style={styles.divider} />
    )
}