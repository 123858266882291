const imageUrls = [
  "https://daoinsights.com/wp-content/webp-express/webp-images/uploads/2021/07/IMG_0434.jpg.webp",
  "https://s.yimg.com/ny/api/res/1.2/iYzuZY6X9TvHom2Z4dvuAQ--/YXBwaWQ9aGlnaGxhbmRlcjt3PTk2MDtjZj13ZWJw/https://o.aolcdn.com/images/dimse/5845cadfecd996e0372f/88f1d10f9e6c1abce41f82e78da992d9662213c8/Y3JvcD0xMDUwJTJDMTIzMCUyQzAlMkMwJnF1YWxpdHk9ODUmZm9ybWF0PWpwZyZyZXNpemU9MTYwMCUyQzE4NzQmaW1hZ2VfdXJpPWh0dHBzJTNBJTJGJTJGcy55aW1nLmNvbSUyRm9zJTJGY3JlYXRyLXVwbG9hZGVkLWltYWdlcyUyRjIwMTktMDUlMkZlY2NjMDQ3MC03YzQyLTExZTktYmRkZi1mYjEwMGYyNmY2NzMmY2xpZW50PWExYWNhYzNlMWIzMjkwOTE3ZDkyJnNpZ25hdHVyZT0zN2EyMzA2ZjRmZGJjZDJkY2FhNzA0MzYzZmNhYzMxYjZjOTlhMjlk",
  "https://1409791524.rsc.cdn77.org/data/images/full/603275/virtual-human-in-k-pop-virtual-influencer-rozy-to-debut-as-idol-blackpink-to-collaborate-with-ai-yg-artist-han-yua.jpg?w=300",
  "https://img6.yna.co.kr/etc/inner/EN/2022/05/18/AEN20220518006500320_01_i_P4.jpg",
  "https://images.squarespace-cdn.com/content/v1/5b7ecf012971149e09426bbb/be54bc83-b281-4691-a4be-3fa08420fb08/duggly+2+Screenshot+2021-11-05+094856.png?format=1500w",
];
  
function getRandomImageUrl() {
    const randomIndex = Math.floor(Math.random() * imageUrls.length);
    return imageUrls[randomIndex];
}

function generateRandomImage(project) {
    const backgroundColor = stringToColor(project.name);
    const initials = project.name
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase())
    .join("");
  
  return `https://ui-avatars.com/api/?name=${initials}&background=${backgroundColor - (1)}&color=fff&size=256`;
}

function stringToColor(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += ("00" + value.toString(16)).substr(-2);
    }
    return color;
}
  
  
export { getRandomImageUrl, generateRandomImage, imageUrls };