import Link from '@mui/material/Link';
import { ReactComponent as GoogleLogo } from '../../../assets/google.svg';
import { oauth } from '../../../service/auth';

const GoogleLoginInButton = ({ from }) => (
  <Link
    href={oauth(from)}
    sx={{
      borderRadius: 1,
      py: "0.5rem",
      columnGap: "1rem",
      textDecoration: "none",
      color: "white",
      cursor: "pointer",
      fontWeight: 500,
      fontSize: '1rem',
      "&:hover": {
        boxShadow: "0 1px 13px 0 rgb(0 0 0 / 15%)",
      },
      boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
    }}
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    <GoogleLogo style={{ height: "1.5rem" }} />
    <span style={{ paddingLeft: "0.5rem", color: "black" }}>Continue with Google</span>
  </Link>
);

export default GoogleLoginInButton;