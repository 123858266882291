import React from "react";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import AddBoxIcon from '@mui/icons-material/AddBox';
import FolderIcon from '@mui/icons-material/Folder';
import HomeIcon from "@mui/icons-material/Home";

import { getInitials } from "../../../utils/CardUtils";

const styles = {
    usertag: {
        marginTop: '20px',
        marginLeft: '15px',
        marginBottom: '20px',
    },
    item: {
        marginTop: '20px',
        marginLeft: '15px',
        '&:hover': {
            bgcolor: '#333333',
        },
    },
    sidebar: {
        width: { sm: 250 },
        bgcolor: '#000000',
        position: 'fixed',
        top: '64px',
        height: 'calc(100% - 64px)',
        overflow: 'auto',
        color: "#ffffff",
        borderRight: '1px solid #333333'
    },
};

const SidebarItems = [
    {
        title: "Home",
        icon: <HomeIcon sx={{ color: '#ffffff' }} />,
        path: "/home",
    },
    {
        title: "Projects",
        icon: <AddBoxIcon sx={{ color: '#ffffff' }} />,
        path: "/projects",
    },
    {
        title: "Folders",
        icon: <FolderIcon sx={{ color: '#ffffff' }} />,
        path: "/folders",
    },
];

const Sidebar = () => {
    const fullname = localStorage.getItem("firstName") + " " + localStorage.getItem("lastName");

    return (
        <Box
            component="nav"
            sx={styles.sidebar}
        >
            <List>
                <ListItem sx={styles.usertag}>
                    <ListItemIcon>
                        <Avatar>
                            {getInitials(localStorage.getItem("firstName"))}
                        </Avatar>
                    </ListItemIcon>
                    <ListItemText primary={fullname} />
                </ListItem>
                {SidebarItems.map((item, index) => (
                    <ListItem key={item.title} sx={styles.item}>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.title} />
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

export default Sidebar;
