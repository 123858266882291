import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";

import Builder from "./pages/Builder/Builder";
import AdvancedBuilder from "./pages/AdvancedBuilder/AdvancedBuilder";
import Login from "./pages/Auth/Login";
import SignUp from "./pages/Auth/SignUp";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import ResetPassword from "./pages/Auth/ResetPassword";
import AppView from "./pages/AppView/AppView";
import Marketplace from "./pages/Marketplace/Marketplace";
import Support from "./pages/Support/Support";
import UserInfo from "./pages/Auth/UserInfo";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/user-info" element={<UserInfo />} />
          <Route path="/forget-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/builder" element={<Builder />} />
          <Route path="/advancedbuilder" element={<AdvancedBuilder />} />
          <Route path="/project" element={<AppView />} />
          <Route path="/marketplace" element={<Marketplace />} />
          <Route path="/support" element={<Support />} />
          <Route path="/*" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
