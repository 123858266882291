import React, { useState } from "react";

import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    CssBaseline,
    Grid,
    Link,
    Paper,
    TextField,
    Typography,
    createTheme,
    ThemeProvider,
    Snackbar,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { styled } from "@mui/system";

import background from "../../assets/background2.png";

import { forgotPassword } from "../../service/auth";

const theme = createTheme();

const ForgotPasswordContent = styled("div")({
    marginTop: "30px",
});

const ForgotPassword = () => {
    const [loading, setLoading] = useState(false);
    const [sendEmailFailed, setSendEmailFailed] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    // const navigate = useNavigate();

    const handleSuccess = (resp) => {
        // navigate("/reset-password");
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        setLoading(true);

        console.log({
            email: data.get("email"),
        });

        forgotPassword(data.get("email"))
            .then((resp) => {
                console.log(resp);

                if (resp.status === 200) {
                    setOpenSnackbar(true);
                    handleSuccess(resp);
                } else {
                    setSendEmailFailed(true);
                }
            })
            .catch((error) => {
                console.log(error);
                setSendEmailFailed(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{ height: "100vh" }}>
                <CssBaseline />
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={openSnackbar}
                    autoHideDuration={3000}
                    onClose={handleCloseSnackbar}
                    message="Reset password email sent!"
                />
                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={5}
                    component={Paper}
                    elevation={6}
                    square
                >
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Forgot password
                        </Typography>
                        <ForgotPasswordContent>
                            <Box
                                component="form"
                                noValidate
                                onSubmit={handleSubmit}
                            >
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                />
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    {loading ? (
                                        <CircularProgress
                                            size={24}
                                            sx={{ color: "#ffffff" }}
                                        />
                                    ) : (
                                        "Send Reset Password Email"
                                    )}
                                </Button>
                                <Grid container marginTop="20px">
                                    <Grid item>
                                        <Link href="/login" variant="body2">
                                            Back to Sign In
                                        </Link>
                                    </Grid>
                                </Grid>
                                {sendEmailFailed && (
                                    <p style={{ color: "red" }}>
                                        Failed to send email. Please try again.
                                    </p>
                                )}
                            </Box>
                        </ForgotPasswordContent>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: `url(${background})`,
                        backgroundRepeat: "no-repeat",
                        backgroundColor: (t) =>
                            t.palette.mode === "light"
                                ? t.palette.grey[50]
                                : t.palette.grey[900],
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                />
            </Grid>
        </ThemeProvider>
    );
};

export default ForgotPassword;
