import React from "react";
import * as THREE from "three";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Center } from "@react-three/drei";

import Block from "./Block";

const Scene = () => {
  return (
    <Canvas
        dpr={window.devicePixelRatio}
        camera={{ position: new THREE.Vector3(8, 5, 40) }}
    >
        <color attach="background" args={["#000000"]} />
        <ambientLight intensity={0.2} />
        <pointLight intensity={1} angle={0.1} penumbra={1} position={[10, 15, 10]} castShadow />
        <Center>
            <Block />
        </Center>
    </Canvas>
  );
}

export default Scene;