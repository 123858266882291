import React from 'react';

const FileSelector = ({ onFolderChange }) => {
    const handleFolderChange = (event) => {
        const folderPath = event.target.files[0]?.path;
        if (folderPath) {
            onFolderChange(folderPath);
        }
    };

    return (
        <label>
            Select Folder:
            <input
                type="file"
                directory=""
                webkitdirectory=""
                mozdirectory=""
                msdirectory=""
                odirectory=""
                onChange={handleFolderChange}
                style={{ display: 'none' }}
            />
        </label>
    );
};

export default FileSelector;
