import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import FolderIcon from '@mui/icons-material/Folder';

import { fetchFileTree } from '../../service/code';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#bd93f9',
    },
    secondary: {
      main: '#8be9fd',
    },
    background: {
      paper: '#1e1e1e',
    },
  },
});

const fileIcon = {
  color: '#808080',
  fontSize: '1.2rem',
  verticalAlign: 'bottom',
  marginRight: 4,
};

export default function FileExplorer() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = {
          data: []
        }; // await fetchFileTree('root');
        if (Array.isArray(result.data) && result.data.length === 0) {
          setData({
            id: 'root',
            name: 'root',
            children: [
              {
                id: 'default',
                name: 'default',
                type: 'folder',
                children: [
                  {
                    id: 'README.md',
                    name: 'README.md',
                    type: 'file',
                  },
                  {
                    id: 'box.js',
                    name: 'box.js',
                    type: 'file',
                  },
                ],
              },
            ],
          });
        } else {
          setData(result.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const renderTree = (nodes) => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id}
      label={
        <span>
          {nodes.type === 'file' ? (
            <InsertDriveFileOutlinedIcon style={fileIcon} />
          ) : nodes.type === 'folder' ? (
            <FolderIcon style={fileIcon} />
          ) : (
            <FolderOpenIcon style={fileIcon} />
          )}
          {nodes.name}
        </span>
      }
      sx={{
        color: nodes.type === 'file' ? '#ccc' : '#fff',
        '& .MuiTreeItem-label': {
          fontSize: '0.85rem',
        },
        '& .MuiTreeItem-iconContainer .MuiSvgIcon-root': {
          fontSize: '1.2rem',
        },
      }}
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ userSelect: 'none', fontSize: '0.85rem' }}>
        {data ? (
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            sx={{ '& .MuiTreeItem-content': { paddingLeft: '8px' } }}
          >
            {renderTree(data)}
          </TreeView>
        ) : (
          <div>Loading...</div>
        )}
      </Box>
    </ThemeProvider>
  );
}
