import React from 'react';

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import NavBar from "../../components/NavBar";

import Scene from "./Scene";
import Codepad from './Codepad';
import FileExplorer from './FileExplorer';

const tabLabelStyle = {
    color: '#fff',
    fontWeight: 'bold',
    textTransform: 'none',
};

export default function Builder() {
    const [tabValue, setTabValue] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <NavBar />
            <Grid container style={{ backgroundColor: "#1e1e1e", height: 'calc(100vh - 64px)' }}>
                <Grid item xs={2} sx={{ overflowY: 'auto', height: '100%', borderRight: '1px solid #333' }}>
                    <FileExplorer />
                </Grid>
                <Grid item xs={10} container direction="column" style={{ height: '100%' }}>
                    <Grid item>
                        <Tabs value={tabValue} onChange={handleTabChange} textColor="inherit" indicatorColor="primary">
                            <Tab label="box.js" style={tabLabelStyle} />
                            <Tab label="README.md" style={tabLabelStyle} />
                        </Tabs>
                    </Grid>
                    <Grid item xs sx={{ overflowY: 'auto', borderBottom: '1px solid #333', boxShadow: 2 }}>
                        <Codepad />
                    </Grid>
                    <Grid item xs sx={{ boxShadow: 2 }}>
                        <Scene style={{ overflowY: 'auto', height: '100%' }} />
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}
