import axios from 'axios';

export const PROD_API_BASE_URL = "https://api.virtualdynohub.xyz";
export const LOCAL_API_BASE_URL = "http://localhost:8080";

export const API_BASE_URL = PROD_API_BASE_URL;

export const ALPHA_API_PREFIX  = "/alpha";
export const BETA_API_PREFIX  = "/beta";
export const V1_API_PREFIX = "/v1";

// Hub API Client for Alpha
export const HubApiClient = axios.create({
  baseURL: API_BASE_URL + ALPHA_API_PREFIX,
  headers: {
    "Content-Type": "application/json",
  },
});

// Hub API Client for Beta
export const HubApiClientBeta = axios.create({
  baseURL: API_BASE_URL + BETA_API_PREFIX,
  headers: {
    "Content-Type": "application/json",
  },
});

// Hub API Client for V1
export const HubApiClientV1 = axios.create({
  baseURL: API_BASE_URL + V1_API_PREFIX,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor to include the access token in the Authorization header
HubApiClient.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a request interceptor to include the access token in the Authorization header
HubApiClientBeta.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a request interceptor to include the access token in the Authorization header
HubApiClientV1.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Print the request to the console
const requestInterceptor = (request) => {
  console.log('Starting Request', JSON.stringify(request, null, 2));
  return request;
};

// Print the response to the console
const responseInterceptor = (response) => {
  console.log('Response:', JSON.stringify(response, null, 2));
  return response;
};

HubApiClient.interceptors.request.use(requestInterceptor);
HubApiClient.interceptors.response.use(responseInterceptor);

HubApiClientBeta.interceptors.request.use(requestInterceptor);
HubApiClientBeta.interceptors.response.use(responseInterceptor);

HubApiClientV1.interceptors.request.use(requestInterceptor);
HubApiClientV1.interceptors.response.use(responseInterceptor);
