import React from "react";

import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
    Box,
    Avatar,
    Rating,
} from "@mui/material";

import Astronaut from "../../../assets/astronaut.png";

const styles = {
    container: {
        paddingTop: "30px",
        paddingBottom: "30px",
    },
    title: {
        fontSize: "40px",
        fontWeight: "700",
        lineHeight: "54px",
        letterSpacing: "-0.03em",
        textAlign: "left",
        marginBottom: "30px",
        color: "#ffffff",
    },
    card: {
        borderRadius: 12,
        boxShadow:
            "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
        margin: "20px",
        width: "300px",
    },
    cardMedia: {
        paddingTop: "56.25%",
    },
    cardContent: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    button: {
        color: "#007aff",
        borderColor: "#007aff",
    },
    description: {
        fontFamily: "'Inter', sans-serif",
        fontSize: "16px",
        margin: "10px",
    },
};

const ListableDialog = ({ open, handleClose, selectedDyno }) => {
    const {
        name,
        snapshot,
        description,
        detailed_description,
        score,
        review_count,
        comments,
    } = selectedDyno;

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle style={styles.dialogTitle}>{name}</DialogTitle>
            <DialogContent style={styles.dialogContent}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <img
                            src={snapshot ? snapshot : Astronaut}
                            alt={name}
                            width="100%"
                            height="auto"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box display="flex" justifyContent="space-around">
                            <Avatar src={Astronaut} />
                        </Box>
                        <Typography variant="body1" style={styles.description}>
                            {description}
                        </Typography>
                        <Typography variant="body1" style={styles.description}>
                            {detailed_description}
                        </Typography>
                        <Box display="flex" alignItems="center">
                            <Rating value={score} precision={0.5} readOnly />
                            <Typography variant="body2">
                                {review_count} reviews
                            </Typography>
                            <Typography
                                variant="body2"
                                style={{ marginLeft: "10px" }}
                            >
                                {comments && comments.length} comments
                            </Typography>
                        </Box>

                        {/* Render comments */}
                        {comments && comments.map((comment) => (
                                <Box
                                    key={comment.ID}
                                    display="flex"
                                    alignItems="center"
                                    marginTop="10px"
                                >
                                    <Avatar src={comment.user.profile} />
                                    <Typography
                                        variant="body2"
                                        style={{ marginLeft: "10px" }}
                                    >
                                        {comment.user.first_name}:{" "}
                                        {comment.content}
                                    </Typography>
                                </Box>
                            ))}
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default ListableDialog;
