import React from "react";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

export default function NotificationMenuButton({ notification, action, onError }) {
    const navigate = useNavigate();

    const updateTextFormat = (text) => {
        const maxLength = 30;

        if (text.length > maxLength) {
            let spaceIndex = text.lastIndexOf(" ", maxLength);
            let firstLine = text.substring(0, spaceIndex);

            let remainingText = text.substring(spaceIndex + 1);
            let secondLine;

            if (remainingText.length > maxLength) {
                spaceIndex = remainingText.lastIndexOf(" ", maxLength);
                secondLine = remainingText.substring(0, spaceIndex) + "...";
            } else {
                secondLine = remainingText;
            }

            return (
                <>
                    <div>{firstLine}</div>
                    <div>{secondLine}</div>
                </>
            );
        }
        return text;
    };

    return (
        <>
            <MenuItem
                onClick={action}
                sx={{
                    '&:hover': { backgroundColor: 'rgba(52, 84, 135, 0.2)' },
                    backgroundColor: '#343541',
                }}
            >
                <ListItemAvatar>
                    <Avatar sx={{ bgcolor: notification.color, width: 24, height: 24 }}>
                        {notification.icon}
                    </Avatar>
                </ListItemAvatar>
                <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Box>
                        <Typography
                            component="span"
                            variant="body2"
                            sx={{
                                fontSize: "10px",
                                color: "rgba(255, 255, 255, 0.7)",
                                display: "block",
                            }}
                        >
                            {notification.createdAt}
                        </Typography>
                    </Box>
                    <Box>
                        <ListItemText
                            primary={
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                        color: "#ffffff",
                                    }}
                                >
                                    {updateTextFormat(notification.text)}
                                </Typography>
                            }
                        />
                    </Box>
                </Box>
            </MenuItem>
            <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }} />
        </>
    );
}
