import React, { useState } from "react";

import { Grid, Container } from "@mui/material";
import ListableDialog from "./components/ListableDialog";

import ProjectCard from "../../components/ProjectCard";
import SectionHeader from "../../components/SectionHeader";

const MarketplaceContent = ({ containerStyle, featuredApps, trendingApps }) => {
    const [open, setOpen] = useState(false);
    const [selectedDyno, setSelectedDyno] = useState({});

    const handleClickOpen = (dyno) => {
        setSelectedDyno(dyno);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Container style={containerStyle}>
                <SectionHeader title="Trending Dynos" color={"#ffffff"} />
                <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                    {trendingApps.map((dyno, index) => (
                        <ProjectCard
                            key={index}
                            project={dyno}
                            handleClickOpen={handleClickOpen}
                        />
                    ))}
                </Grid>

                <SectionHeader title="Featured Dynos" color={"#ffffff"} />
                <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                    {featuredApps.map((dyno, index) => (
                        <ProjectCard
                            key={index}
                            project={dyno}
                            handleClickOpen={handleClickOpen}
                        />
                    ))}
                </Grid>
            </Container>

            {/* Dialog to view the detailed view of market item. */}
            <ListableDialog
                open={open}
                handleClose={handleClose}
                selectedDyno={selectedDyno}
            />
        </>
    );
};

export default MarketplaceContent;
