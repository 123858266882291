import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { signup } from '../../service/auth';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();

// Update SignUp function
export default function SignUp() {
    const navigate = useNavigate();
    
    const [signUpFailed, setSignUpFailed] = useState(false);

    const handleSuccess = (resp) => {
        if (resp.data.access_token === undefined || resp.data.refresh_token === undefined) {
          console.error("Access token or refresh token is undefined");
          return; 
        }
    
        // Save tokens to local storage
        localStorage.setItem("id", resp.data.id);
        localStorage.setItem("username", resp.data.username);
        localStorage.setItem("firstName", resp.data.first_name);
        localStorage.setItem("lastName", resp.data.last_name);
        localStorage.setItem("email", resp.data.email);
        localStorage.setItem("profile", resp.data.profile);
        localStorage.setItem("accessToken", resp.data.access_token);
        localStorage.setItem("refreshToken", resp.data.refresh_token);
    
        // Successful sign up, redirect to login page or show success message
        console.log("Sign up successful");
    
        // redirect to login page
        navigate("/project");
    }
    
    // Update handleSubmit function
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        
        signup(data.get('username'), data.get('password'), data.get('email'), data.get('firstName'), data.get('lastName'), '')
            .then(resp => {
                if (resp.status === 201) {
                    handleSuccess(resp);
                } else {
                  // Show error message based on response
                  console.error(`Sign up failed: ${resp.statusText}`);
                  setSignUpFailed(true);
                }
            })
            .catch(err => {
                console.error(err);
                setSignUpFailed(true);
            }   
        );
    };

    return(
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign up
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="username"
                                label="Username"
                                name="username"
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="given-name"
                                name="firstName"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                autoComplete="family-name"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                            control={<Checkbox value="allowExtraEmails" color="primary" />}
                            label="I want to receive inspiration, marketing promotions and updates via email."
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign Up
                    </Button>
                        { signUpFailed && <p style={{ color: 'red' }}>Sign up failed. Please try again.</p> }
                    <Grid container justifyContent="flex-end">
                    <Grid item>
                        <Link href="/login" variant="body2">
                        Already have an account? Sign in
                        </Link>
                    </Grid>
                    </Grid>
                </Box>
                </Box>
            </Container>
        </ThemeProvider>
      )
}