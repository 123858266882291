const notifications = [
    {
        id: 1,
        text: "@elonmusk just subscribed to your project `Deno'!",
        createdAt: "5 minutes ago",
        icon: "E",
        color: "#3f51b5",
    },
    {
        id: 2,
        text: "@dan_abramov just subscribed to your project `Deno'!",
        createdAt: "15 minutes ago",
        icon: "D",
        color: "#4caf50",
    },
    {
        id: 3,
        text: "New comment on your project `Deno'!",
        createdAt: "1 day ago",
        icon: "C",
        color: "#f44336",
    },
    {
        id: 4,
        text: "@chrisoncode commented on your project `Deno'!",
        createdAt: "2 days ago",
        icon: "C",
        color: "#ff9800",
    },
];

export default notifications;
