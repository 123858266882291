import React, { useState } from "react";
import { Typography, IconButton } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const videos = [
    "https://assets.mixkit.co/videos/preview/mixkit-video-gamer-using-vr-glasses-40457-large.mp4",
    "https://assets.mixkit.co/videos/preview/mixkit-infinity-corridor-with-futuristic-style-32960-large.mp4",
    "https://assets.mixkit.co/videos/preview/mixkit-high-tech-circuit-board-with-processor-47051-large.mp4",
];

const styles = {
    videoContainer: {
        position: "relative",
        height: "50vh",
        overflow: "hidden",
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        borderRadius: "15px",
        sideMargin: 30,
    },
    videos: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
    },
    header: {
        fontWeight: "bold",
        lineHeight: "54px",
        letterSpacing: "-0.01em",
        textAlign: "left",
        marginBottom: "30px",
        color: "#ffffff",
        position: "absolute",
        bottom: "10%",
        left: "5%",
        width: "90%",
    },
    arrow: {
        color: "white",
        position: "absolute",
        top: "50%",
        fontSize: "3em",
        cursor: "pointer",
        transform: "translateY(-50%)",
    },
    arrowLeft: {
        left: "10px",
    },
    arrowRight: {
        right: "10px",
    },
};

const VideoContainer = ({marginLeft}) => {
    const [currentVideo, setCurrentVideo] = useState(0);
    const [isHovered, setIsHovered] = useState(false);

    const handleArrowClick = (direction) => {
        if (direction === "left") {
            setCurrentVideo((prev) => (prev - 1 + videos.length) % videos.length);
        } else if (direction === "right") {
            setCurrentVideo((prev) => (prev + 1) % videos.length);
        }
    };

    const videoContainerStyle = {
        ...styles.videoContainer,
        marginLeft: `${marginLeft + styles.videoContainer.sideMargin}px`,
        width: `calc(100% - ${marginLeft + styles.videoContainer.sideMargin * 2}px)`,
    };

    return (
        <div 
            style={videoContainerStyle} 
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <video
                style={styles.videos}
                src={videos[currentVideo]}
                autoPlay
                loop
                muted
            ></video>
            {/* Add the arrow icons */}
            <IconButton 
                style={{...styles.arrow, ...styles.arrowLeft, opacity: isHovered ? 1 : 0}} 
                onClick={() => handleArrowClick("left")}
            >
                <ArrowBackIosIcon fontSize="inherit" />
            </IconButton>
            <IconButton 
                style={{...styles.arrow, ...styles.arrowRight, opacity: isHovered ? 1 : 0}} 
                onClick={() => handleArrowClick("right")}
            >
                <ArrowForwardIosIcon fontSize="inherit" />
            </IconButton>
            {/* Add the header container */}
            <Typography
                style={styles.header}
                sx={{
                    fontSize: {
                        xs: "25px",
                        sm: "50px",
                    },
                }}
            >
                {"Dyno Marketplace"}
            </Typography>
        </div>
    );
};

export default VideoContainer;
