import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import UseDragScroll from "../../hooks/UseDragScroll";
import FolderCard from "../../components/FolderCard";
import SectionHeader from "../../components/SectionHeader";
import IconSelector from "../../utils/IconUtils";

import foldersData from "./mockdata/Folders";

const styles = {
  title: {
    fontWeight: 600,
    marginBottom: "30px",
    color: "#ffffff",
  },
  card: {
    borderRadius: 12,
    boxShadow:
      "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
    margin: "20px",
    width: "300px",
    display: "flex",
    flexDirection: "column",
  },
  cardContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    overflowX: "auto"
  }
};

const Folders = () => {
    const dragScroll = UseDragScroll();
    const [folders, setFolders] = useState(foldersData);
    const [hasMore, setHasMore] = useState(true);
  
    const fetchMoreData = () => {
      if (folders.length >= foldersData.length * 5) {
        setHasMore(false);
        return;
      }
      setTimeout(() => {
        const newFolders = foldersData.map((folder, index) => ({
          ...folder,
          name: `${folder.name} - ${folders.length + index}`
        }));
        setFolders(foldersData.concat(newFolders));
      }, 1500);
    };
  
    return (
      <div>
        <SectionHeader title="Folders" />
        <div
          ref={dragScroll.ref}
          onMouseDown={dragScroll.onMouseDown}
          onMouseLeave={dragScroll.onMouseLeave}
          onMouseUp={dragScroll.onMouseUp}
          onMouseMove={dragScroll.onMouseMove}
          style={{ ...styles.cardContainer, overflowX: "scroll", position: "relative" }}
        >
        <InfiniteScroll
          dataLength={foldersData.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
          scrollableTarget="scrollingContainer"
          scrollThreshold="100px"
          style={{ display: "flex", overflow: "hidden" }}
        >
          {foldersData.map((folder) => (
            <FolderCard key={folder.name} folder={folder} icon={IconSelector(folder.icon)}/>
          ))}
          </InfiniteScroll>
        </div>
      </div>
    );
  };

export default Folders;