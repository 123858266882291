import { imageUrls } from "../../../utils/RandomImage";

const recentProjectsData = [
    {
        name: "Starter Project",
        description: "The starter project for Virtual Dyno Immersive App.",
        snapshot: imageUrls[0],
    },
    {
        name: "Project A",
        description: "This is a recent project A.",
        snapshot: imageUrls[1],
    },
    {
        name: "Project B",
        description: "This is example project B.",
        snapshot: imageUrls[2],
    },
    {
        name: "Project C",
        description: "This is example project C.",
        snapshot: imageUrls[3],
    },
    {
        name: "Project D",
        description: "This is example project D.",
        snapshot: imageUrls[4],
    },
];

export default recentProjectsData;