import {
    Grid,
    Paper,
    Typography,
} from '@mui/material';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                paper: {
                  padding: "16px",
                  textAlign: "left",
                  color: "#0f0f0f",
                },
            }
        }
    }
});

const SupportGridItem = ({ title, content }) => {
    return (
        <Grid item xs={12} sm={6}>
        <Paper style={theme.components.MuiOutlinedInput.styleOverrides.paper} variant="outlined" square>
            <Typography variant="h6" gutterBottom>
            {title}
            </Typography>
            <Typography variant="body1" gutterBottom>
            {content}
            </Typography>
        </Paper>
        </Grid>
    );
}

export default SupportGridItem;