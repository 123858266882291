import React from "react";
import { useNavigate } from "react-router-dom";

import Alert from '@mui/material/Alert';
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";

import { logout } from "../service/auth";

export default function SettingMenuButton({ setting, action, onError }) {
    const navigate = useNavigate();

    const handleLogout = async (navigate) => {
        try {
            const response = await logout();
            if (response.status >= 200 && response.status < 300) {
                // Redirect the user to the login page
                navigate("/login");
            } else {
                if (response.status === null) {
                    onError(<Alert severity="error">Logout failed</Alert>);
                }
                if (response.status === 401) {
                    onError(<Alert severity="error">You are not logged in</Alert>);
                }
                if (response.status === 403) {
                    onError(<Alert severity="error">You are not authorized to perform this action</Alert>);
                }
                onError(<Alert severity="error">Logout failed with status {response.status}</Alert>);
            }
        } catch (error) {
            // Handle any errors that might occur during logout
            onError(<Alert severity="error">An error occurred while logging out: {error.message}</Alert>);
        }
    };    
    
    const handleAccount = (navigate) => {
        // Redirect the user to the login page
        navigate("/user-info");
    }
    
    const handleDashboard = (navigate) => {
        // Redirect the user to the login page
        navigate("/project");
    }

    const renderSwitch = (setting, navigate) => {
        switch (setting) {
            case "Account":
                return (
                    <Typography
                        key="Account"
                        color="inherit"
                        onClick={() => handleAccount(navigate)}
                        style={{ textTransform: "none" }}
                    >
                        {setting}
                    </Typography>
                );
            case "Dashboard":
                return (
                    <Typography
                        key="Dashboard"
                        color="inherit"
                        onClick={() => handleDashboard(navigate)}
                        style={{ textTransform: "none" }}
                    >
                        {setting}
                    </Typography>
                );
            case "Logout":
                return (
                    <Typography
                        key="Logout"
                        color="inherit"
                        onClick={() => handleLogout(navigate)}
                        style={{ textTransform: "none" }}
                    >
                        {setting}
                    </Typography>
                );
            default:
                return <Typography textAlign="center">{setting}</Typography>;
        }
    }

    return (
        <MenuItem onClick={action}>{renderSwitch(setting, navigate)}</MenuItem>
    );
}
