import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { styled } from "@mui/system";

import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    CssBaseline,
    Grid,
    Link,
    Paper,
    TextField,
    Typography,
    createTheme,
    ThemeProvider,
    Snackbar,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import background from "../../assets/background2.png";

import { resetPassword, validateToken } from "../../service/auth";

const theme = createTheme();

const ResetPasswordContent = styled("div")({
    marginTop: "30px",
});

const ResetPassword = () => {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [token, setToken] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [resetPasswordFailed, setResetPasswordFailed] = useState(false);
    const [passwordNotMatch, setPasswordNotMatch] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("token");

        if (token) {
            // Do something with the token, such as store it in state or local storage
            setToken(token);
            const decoded = jwt_decode(token);
            console.log(decoded);
            validateToken(token)
                .then((resp) => {
                    console.log(resp);
                    if (resp.status === 200) {
                    } else {
                        navigate("/login");
                    }
                })
                .catch((error) => {
                    console.log(error);
                    navigate("/login");
                });
            setEmail(decoded.email);
        } else {
            // No token found in the URL
            // Do something else, such as redirect to the login page
            navigate("/login");
        }
    });

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    const handleSuccess = (resp) => {
        // navigate("/login");
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        setLoading(true);

        console.log({
            email: email,
            token: token,
            newPassword: data.get("new-password"),
            confirmPassword: data.get("confirm-password"),
        });

        if (data.get("new-password") !== data.get("confirm-password")) {
            setPasswordNotMatch(true);
            setResetPasswordFailed(true);
            setLoading(false);
            return;
        }

        resetPassword(email, data.get("new-password"), token)
            .then((resp) => {
                console.log(resp);

                if (resp.status === 200) {
                    setOpenSnackbar(true);
                    handleSuccess(resp);
                } else {
                    setResetPasswordFailed(true);
                }
            })
            .catch((error) => {
                console.log(error);
                setResetPasswordFailed(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{ height: "100vh" }}>
                <CssBaseline />
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={openSnackbar}
                    autoHideDuration={3000}
                    onClose={handleCloseSnackbar}
                    message="Reset password successfully!"
                />
                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={5}
                    component={Paper}
                    elevation={6}
                    square
                >
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Reset password
                        </Typography>
                        <ResetPasswordContent>
                            <Box
                                component="form"
                                noValidate
                                onSubmit={handleSubmit}
                            >
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="new-password"
                                    label="New password"
                                    name="new-password"
                                    autoComplete="new-password"
                                    type="password"
                                    autoFocus
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="confirm-password"
                                    label="Confirm password"
                                    name="confirm-password"
                                    type="password"
                                    autoComplete="confirm-password"
                                />
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    {loading ? (
                                        <CircularProgress
                                            size={24}
                                            sx={{ color: "#ffffff" }}
                                        />
                                    ) : (
                                        "Reset password"
                                    )}
                                </Button>
                                <Grid container marginTop="20px">
                                    <Grid item>
                                        <Link href="/login" variant="body2">
                                            Back to sign in
                                        </Link>
                                    </Grid>
                                </Grid>
                                {resetPasswordFailed && (
                                    <p style={{ color: "red" }}>
                                        {passwordNotMatch
                                            ? "Your passwords do not match. "
                                            : "Failed to reset password. "}
                                        Please try again.
                                    </p>
                                )}
                            </Box>
                        </ResetPasswordContent>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: `url(${background})`,
                        backgroundRepeat: "no-repeat",
                        backgroundColor: (t) =>
                            t.palette.mode === "light"
                                ? t.palette.grey[50]
                                : t.palette.grey[900],
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                />
            </Grid>
        </ThemeProvider>
    );
};

export default ResetPassword;
