const foldersData = [
    {
      name: "Starred",
      description: "A folder containing starred projects.",
      icon: "starred",
    },
    {
      name: "Work",
      description: "A folder containing working projects.",
      icon: "work",
    },
];

export default foldersData;