import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    CssBaseline,
    FormControlLabel,
    Grid,
    Link,
    Paper,
    TextField,
    Typography,
    createTheme,
    ThemeProvider,
} from "@mui/material";
import Fingerprint from "@mui/icons-material/Fingerprint";
import { styled } from "@mui/system";

import GoogleLoginInButton from "./components/GoogleLoginButton";
import background from "../../assets/background.png";

import { login } from "../../service/auth";

const darkTheme = createTheme({
    palette: {
        mode: "dark",
    },
});

const lightTheme = createTheme({
    palette: {
        mode: "light",
    },
});

const BackgroundImage = styled(Grid)(({ theme }) => ({
    backgroundImage: `url(${background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    [theme.breakpoints.down("md")]: {
        display: "none",
    },
}));

const Login = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [theme, setTheme] = useState(lightTheme);
    const [loginFailed, setLoginFailed] = useState(false);
    const [rememberedEmail, setRememberedEmail] = useState("");
    const [loading, setLoading] = useState(false);
    
    const from = location.state?.from?.pathname || "/project";

    useEffect(() => {
        const savedEmail = localStorage.getItem("rememberEmail");
        if (savedEmail) {
            setRememberedEmail(savedEmail);
        }
    }, []);

    const toggleTheme = () => {
        setTheme((prevTheme) => {
            if (prevTheme === lightTheme) {
                return darkTheme;
            } else {
                return lightTheme;
            }
        });
    };

    const handleSuccess = (resp) => {
        if (
            resp.data.access_token === undefined ||
            resp.data.refresh_token === undefined
        ) {
            console.error("Access token or refresh token is undefined");
            return;
        }
        if (
            resp.data.id === undefined ||
            resp.data.username === undefined ||
            resp.data.email === undefined ||
            resp.data.profile === undefined
        ) {
            console.error("User id, username, email, or profile is undefined");
            return;
        }

        // Save tokens to local storage
        localStorage.setItem("id", resp.data.id);
        localStorage.setItem("username", resp.data.username);
        localStorage.setItem("email", resp.data.email);
        localStorage.setItem("profile", resp.data.profile);
        localStorage.setItem("firstName", resp.data.first_name);
        localStorage.setItem("lastName", resp.data.last_name);
        localStorage.setItem("accessToken", resp.data.access_token);
        localStorage.setItem("refreshToken", resp.data.refresh_token);

        navigate("/project");
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        setLoading(true);

        if (data.get("remember")) {
            localStorage.setItem("rememberEmail", data.get("email"));
        } else {
            localStorage.removeItem("rememberEmail");
        }

        login(data.get("email"), data.get("password"))
            .then((resp) => {
                console.log(resp);

                if (resp.status === 200) {
                    setLoginFailed(false);
                    handleSuccess(resp);
                } else {
                    setLoginFailed(true);
                }
            })
            .catch(() => {
                setLoginFailed(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{ height: "100vh" }}>
                <CssBaseline />
                {/* <Button
                    sx={{
                    position: 'absolute',
                    bottom: 16,
                    left: 16,
                    bgcolor: 'background.default',
                    color: 'text.primary',
                    borderRadius: '50%',
                    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
                    '&:hover': {
                        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
                    },
                    }}
                    onClick={toggleTheme}
                >
                    {theme.palette.mode === 'light' ? <Brightness4Icon /> : <Brightness7Icon />}
                </Button> */}
                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={5}
                    component={Paper}
                    elevation={6}
                    square
                >
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                            <Fingerprint />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            {" "}
                            Sign in{" "}
                        </Typography>
                        <Box
                            component="form"
                            noValidate
                            onSubmit={handleSubmit}
                            sx={{ mt: 1 }}
                        >
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Username or email"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                defaultValue={rememberedEmail}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value="remember"
                                        color="primary"
                                    />
                                }
                                label="Remember me"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                    borderRadius: 1,
                                    py: "0.5rem",
                                    columnGap: "1rem",
                                    textTransform: "none",
                                    cursor: "pointer",
                                    fontWeight: 500,
                                    fontSize: "1rem",
                                    "&:hover": {
                                        boxShadow:
                                            "0 1px 13px 0 rgb(0 0 0 / 15%)",
                                    },
                                    boxShadow:
                                        "0px 0px 5px 0px rgba(0,0,0,0.75)",
                                }}
                            >
                                {loading ? (
                                    <CircularProgress
                                        size={24}
                                        sx={{ color: "#ffffff" }}
                                    />
                                ) : (
                                    "Sign In"
                                )}
                            </Button>
                            <GoogleLoginInButton from={from} />
                            <Grid container>
                                <Grid item xs={6}>
                                    <Link
                                        href="/forget-password"
                                        variant="body2"
                                        textAlign="start"
                                    >
                                        <p>Forgot password?</p>
                                    </Link>
                                </Grid>
                                <Grid item xs={6}>
                                    <Link
                                        href="/signup"
                                        variant="body2"
                                        textAlign="end"
                                    >
                                        <p>Don't have an account? Sign Up</p>
                                    </Link>
                                </Grid>
                            </Grid>
                            {loginFailed && (
                                <p style={{ color: "red" }}>
                                    Login failed. Please try again.
                                </p>
                            )}
                        </Box>
                    </Box>
                </Grid>
                <BackgroundImage item xs={false} sm={4} md={7} />
            </Grid>
        </ThemeProvider>
    );
};

export default Login;
