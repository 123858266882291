import React, { useState } from "react";

import InfiniteScroll from "react-infinite-scroll-component";

import UseDragScroll from "../../hooks/UseDragScroll";
import ProjectCard from "../../components/ProjectCard";
import SectionHeader from "../../components/SectionHeader";

import recentProjectsData from "./mockdata/RecentProjects";

const styles = {
    title: {
      fontSize: "30px",
      fontWeight: 600, 
      marginBottom: "30px" ,
      marginTop: "60px",
      color: "#ffffff",
    },
    cardContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      justifyContent: "space-between",
      overflowX: "auto"
    },
  }

const RecentProjects = () => {
    const dragScroll = UseDragScroll();
    const [projects, setProjects] = useState(recentProjectsData);
    const [hasMore, setHasMore] = useState(true);
  
    const fetchMoreData = () => {
      if (projects.length >= recentProjectsData.length * 5) {
        setHasMore(false);
        return;
      }
      setTimeout(() => {
        const newProjects = recentProjectsData.map((project, index) => ({
          ...project,
          name: `${project.name} - ${projects.length + index}`
        }));
        setProjects(projects.concat(newProjects));
      }, 1500);
    };
  
    return (
      <div>
        <SectionHeader title="Recent Projects" />
        <div
          ref={dragScroll.ref}
          onMouseDown={dragScroll.onMouseDown}
          onMouseLeave={dragScroll.onMouseLeave}
          onMouseUp={dragScroll.onMouseUp}
          onMouseMove={dragScroll.onMouseMove}
          style={{ ...styles.cardContainer, overflowX: "scroll", position: "relative" }}
        >
          <InfiniteScroll
            dataLength={projects.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<h4>Loading...</h4>}
            scrollableTarget="scrollingContainer"
            scrollThreshold="100px"
            style={{ display: "flex", overflow: "hidden" }}
          >
            {projects.map((project) => (
              <ProjectCard key={project.name} project={project} />
            ))}
          </InfiniteScroll>
        </div>
      </div>
    );
};

export default RecentProjects;