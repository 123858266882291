import Typography from "@mui/material/Typography";
import DynoDivider from "./DynoDivider";

const styles = (color) => ({
    title: {
      fontSize: "30px",
      fontWeight: 600, 
      marginBottom: "30px",
      marginTop: "60px",
      color: color || "#b3b3b3",
    },
});

export default function SectionHeader({ title, color }) {
    return (
        <>
            <Typography component="h2" variant="h4" style={styles(color).title}>
                {title}
            </Typography>
            <DynoDivider />
        </>
    );
}