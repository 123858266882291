import React from 'react';
import MonacoEditor from '@monaco-editor/react';
import './monaco-theme.css';

const CodeEditor = () => {
    return (
        <MonacoEditor
            height="100%"
            width="100%"
            theme="vs-dark"
            language="python"
            options={{
                minimap: {
                    enabled: false,
                },
            }}
        />
    );
};

export default CodeEditor;
