import React from "react";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import FeaturedVideoIcon from '@mui/icons-material/FeaturedVideo';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import InsightsIcon from '@mui/icons-material/Insights';

import { getInitials } from "../../../utils/CardUtils";

const styles = {
    usertag: {
        marginTop: '20px',
        marginLeft: '15px',
        marginBottom: '20px',
    },
    item: {
        marginTop: '20px',
        marginLeft: '15px',
        '&:hover': {
            bgcolor: '#333333',
        },
    },
    sidebar: {
        width: { sm: 250 },
        bgcolor: '#000000',
        position: 'fixed',
        top: '64px',
        height: 'calc(100% - 64px)',
        overflow: 'auto',
        color: "#ffffff",
        borderRight: '1px solid #333333'
    },
};

const SidebarItems = [
    {
        title: "Popular",
        icon: <InsightsIcon sx={{ color: '#ffffff' }} />,
    },
    {
        title: "Featured",
        icon: <FeaturedVideoIcon sx={{ color: '#ffffff' }} />,
    },
    {
        title: "Filters",
        icon: <FilterAltIcon sx={{ color: '#ffffff' }} />,
    },
];

const Sidebar = ({width}) => {
    const fullname = localStorage.getItem("firstName") + " " + localStorage.getItem("lastName");

    const sidebarStyle = {
        ...styles.sidebar,
        width: width,
    };

    return (
        <Box
            component="nav"
            sx={sidebarStyle}
        >
        <List>
            <ListItem sx={styles.usertag}>
                <ListItemIcon>
                    <Avatar>
                        {getInitials(localStorage.getItem("firstName"))}
                    </Avatar>
                </ListItemIcon>
                <ListItemText primary={fullname} />
            </ListItem>
            {SidebarItems.map((item, index) => (
            <ListItem key={item.title} sx={styles.item}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.title} />
            </ListItem>
            ))}
        </List>
        </Box>
    );
};

export default Sidebar;
