import React from 'react';
import { Link } from 'react-router-dom';

import {
    Avatar,
    Card,
    CardContent,
    Grid,
    IconButton,
    Typography,
    Menu,
    MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { getInitials } from "../utils/CardUtils";
import { getRandomImageUrl } from "../utils/RandomImage";

const styles = {
    title: {
      fontWeight: 600,
      marginBottom: "30px",
      color: "#ffffff",
    },
    card: {
      width: "100%",
      marginBottom: "10px",
      backgroundColor: "#3f3f3f",
    },
    iconButton: {
      padding: "5px"
    },
    link: {
      textDecoration: "none",
    },
    text: {
        color: "#ffffff",
        fontWeight: 'bold',
    },
    image: {
        width: "50px",
        height: "50px",
        borderRadius: "5px",
    },
    vertIcon: {
        color: "#b3b3b3",
    },
    cardHover: {
        '&:hover': {
            backgroundColor: "#4f4f4f",
            cursor: 'pointer',
        },
    },
};

function ProjectRow(project, index, handleMenuClick, handleMenuClose, anchorEl) {
    return <Card style={styles.card} sx={styles.cardHover} key={project.id}>
      <CardContent>
        <Grid container alignItems="center">
          {/* Column 1: Image with title */}
          <Grid item xs={4} container alignItems="center">
            <Grid item xs={4}>
              <img
                src={project.snapshot ? project.snapshot : getRandomImageUrl()}
                alt="Project snapshot"
                style={styles.image} />
            </Grid>
            <Grid item xs={8}>
              <Link to={`/builder`} style={styles.link}>
                <Typography style={styles.text}>{project.name}</Typography>
              </Link>
            </Grid>
          </Grid>
          {/* Column 2: Collaborators */}
          <Grid item xs={3} container alignItems="center" spacing={1}>
            {project.collaborators.map((collaborator, idx) => (
              <Grid item key={idx}>
                <Avatar>{getInitials(collaborator)}</Avatar>
              </Grid>
            ))}
          </Grid>
          {/* Column 3: Created time */}
          <Grid item xs={3}>
            <Typography variant="body2" style={styles.text}>
              {project.createdTime ? project.createdTime : new Date().toLocaleDateString()}
            </Typography>
          </Grid>
          {/* Column 4: Icon to open menu */}
          <Grid item xs={2}>
            <IconButton onClick={(event) => handleMenuClick(event, index)}>
              <MoreVertIcon style={styles.vertIcon} />
            </IconButton>
            <Menu
              anchorEl={anchorEl[index]}
              keepMounted
              open={Boolean(anchorEl[index])}
              onClose={() => handleMenuClose(index)}
            >
              <MenuItem onClick={() => handleMenuClose(index)}>Edit</MenuItem>
              <MenuItem onClick={() => handleMenuClose(index)}>Delete</MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </CardContent>
    </Card>;
  }

export default ProjectRow;