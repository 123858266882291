import Astronaut from "../../../assets/astronaut.png";
import SpaceMonster from "../../../assets/spacemonster.png";
import Guardian from "../../../assets/guardian.png";
import Explorer from "../../../assets/explorer.png";
import { imageUrls } from "../../../utils/RandomImage";

const popularDynos = [
    {
        name: "SpaceWoman AI",
        description: "SpaceWoman AI (Space Traveling)",
        detailed_description: "SpaceWoman AI (Space Traveling) depicts a futuristic version of a female astronaut, equipped with advanced space technology and a sense of adventure. With this NFT, you can own a piece of the future and inspire your own journey of discovery and exploration.",
        snapshot: Astronaut,
        score: 4.5,
        reviewCount: 128,
        comments: [
            {
                id: 1,
                user: {
                    name: "John Doe",
                    avatar: "https://randomuser.me/api/portraits/men/1.jpg",
                },
                text: "This Virtual Human is amazing, I love the details on the space suit!"
            },
            {
                id: 2,
                user: {
                    name: "Jane Smith",
                    avatar: "https://randomuser.me/api/portraits/women/2.jpg",
                },
                text: "I can't stop playing with it!"
            },
        ]
    },
    {
        name: "Space BabyMonster",
        description: "SpaceBabyMonster (Jungle Follower)",
        detailed_description: "SpaceBabyMonster (Jungle Follower) is an adorable baby monster from outer space, with a unique ability to navigate through dense jungles and explore new worlds. With this NFT, you can own a one-of-a-kind creature that will keep you company on all your intergalactic adventures.",
        snapshot: SpaceMonster,
        score: 4.8,
        reviewCount: 256,
        comments: [
            {
                id: 1,
                user: {
                    name: "Mark Johnson",
                    avatar: "https://randomuser.me/api/portraits/men/3.jpg",
                },
                text: "I just bought this NFT and it's even cuter in person!"
            },
            {
                id: 2,
                user: {
                    name: "Sarah Lee",
                    avatar: "https://randomuser.me/api/portraits/women/4.jpg",
                },
                text: "The details on this NFT are incredible, I love the little antennas!"
            },
            // ...more comments
        ]
    },
    {
        name: "I-GirlFriend",
        description: "I-GirlFriend (Virtual Companion)",
        detailed_description: "I-GirlFriend (Virtual Companion) is a virtual companion that will keep you company on all your adventures. She's always there for you, no matter what. With this NFT, you can own a one-of-a-kind companion that will never leave your side.",
        snapshot: imageUrls[0],
        score: 4.5,
        reviewCount: 128,
        comments: [
            {
                id: 1,
                user: {
                    name: "John Doe",
                    avatar: "https://randomuser.me/api/portraits/men/1.jpg",
                },
                text: "I bought I-GirlFriend and she's amazing! I love her so much!"
            },
            {
                id: 2,
                user: {
                    name: "Jane Smith",
                    avatar: "https://randomuser.me/api/portraits/women/2.jpg",
                },
                text: "I was skeptical at first, but I-GirlFriend is the best thing that's ever happened to me."
            },
        ]
    },
    {
        name: "Space BabyMonster",
        description: "SpaceBabyMonster (Jungle Follower)",
        detailed_description: "SpaceBabyMonster (Jungle Follower) is an adorable baby monster from outer space, with a unique ability to navigate through dense jungles and explore new worlds. With this NFT, you can own a one-of-a-kind creature that will keep you company on all your intergalactic adventures.",
        snapshot: SpaceMonster,
        score: 4.8,
        reviewCount: 256,
        comments: [
            {
                id: 1,
                user: {
                    name: "Mark Johnson",
                    avatar: "https://randomuser.me/api/portraits/men/3.jpg",
                },
                text: "I just bought this NFT and it's even cuter in person!"
            },
            {
                id: 2,
                user: {
                    name: "Sarah Lee",
                    avatar: "https://randomuser.me/api/portraits/women/4.jpg",
                },
                text: "The details on this NFT are incredible, I love the little antennas!"
            },
            // ...more comments
        ]
    },
];

const featuredDynos = [
    {
        name: "Cosmic Explorer",
        description: "Cosmic Explorer (Starry Adventurer)",
        detailed_description: "Cosmic Explorer (Starry Adventurer) represents an intrepid traveler of the cosmos, seeking out new stars and uncharted territories. This NFT embodies the spirit of exploration and the desire to uncover the mysteries of the universe.",
        snapshot: Explorer,
        score: 4.7,
        reviewCount: 95,
        comments: [
            {
                id: 1,
                user: {
                    name: "Michael Stevens",
                    avatar: "https://randomuser.me/api/portraits/men/15.jpg",
                },
                text: "Absolutely love the concept behind this NFT. It's stunning!"
            },
            {
                id: 2,
                user: {
                    name: "Emily Davis",
                    avatar: "https://randomuser.me/api/portraits/women/12.jpg",
                },
                text: "The design is so detailed and the colors are amazing. Great job!"
            },
        ]
    },
    {
        name: "Galactic Guardian",
        description: "Galactic Guardian (Protector of the Cosmos)",
        detailed_description: "Galactic Guardian (Protector of the Cosmos) is a powerful being dedicated to safeguarding the universe from threats both known and unknown. This NFT symbolizes strength, resilience, and unwavering commitment to the protection of all life in the cosmos.",
        snapshot: Guardian,
        score: 4.9,
        reviewCount: 150,
        comments: [
            {
                id: 1,
                user: {
                    name: "Jack Thompson",
                    avatar: "https://randomuser.me/api/portraits/men/25.jpg",
                },
                text: "The Galactic Guardian is an impressive NFT. I can't wait to see more like it!"
            },
            {
                id: 2,
                user: {
                    name: "Sophia Martinez",
                    avatar: "https://randomuser.me/api/portraits/women/22.jpg",
                },
                text: "I'm blown away by the level of detail in this NFT. It's fantastic!"
            },
            // ...more comments
        ]
    },
];

export {
    popularDynos,
    featuredDynos,
};