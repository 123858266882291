import React, { Fragment, useState } from 'react';

import { ThemeProvider } from 'styled-components'; 
import { createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from "@mui/material/Stack";
import Grid from '@mui/material/Grid';

import NavBar from "../../components/NavBar";
import DynoDivider from '../../components/DynoDivider';
import SupportGridItem from './components/SupportGridItem';

import {handleAlertError} from "../../utils/AlertUtils";

const theme = createTheme({
    palette: {
      background: {
        default: "#1d1d1d", 
      },
      text: {
        primary: "#ffffff", 
      },
    },
    container: {
      paddingTop: "30px", 
      paddingBottom: "30px",
    },
    title: {
      fontSize: "40px",
      fontWeight: "700",
      lineHeight: "54px",
      letterSpacing: "-0.03em",
      textAlign: "left",
      marginBottom: "30px", 
      color: "white",
    },
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                paper: {
                  padding: "16px",
                  textAlign: "left",
                  color: "#0f0f0f",
                },
            }
        }
    }
});

export default function Support() {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          minHeight: "100vh",
          bgcolor: theme.palette.background.default,
        }}
      >
        <Fragment>
            { /* NavBar component */}
            <NavBar handleError={(errorComponent) => handleAlertError(setAlertMessage, setShowAlert, errorComponent)} />
            {showAlert && (
              <Stack
                spacing={2}
                sx={{
                  width: "50%",
                  position: "fixed",
                  bottom: 8,
                  right: 8,
                  zIndex: 2000,
                }}
              >
                {alertMessage}
              </Stack>        
            )}

            { /* Main component */}
            <Container style={theme.container}>
                <Typography style={theme.title}>
                     Hub Support
                </Typography>
                <DynoDivider />
                <Grid container spacing={3}>
                    <SupportGridItem
                      title="How to create a new virtual dyno?"
                      content="To create a new virtual dyno, go to the 'Virtual Dynos' page and click on the 'Create Dyno' button. Fill in the required information and click on 'Create'."
                    />
                    <SupportGridItem
                      title="How to delete a virtual dyno?"
                      content="To delete a virtual dyno, go to the 'Virtual Dynos' page and click on the 'Delete' button next to the dyno you want to delete. Confirm the deletion by clicking on 'Yes'."
                    />
                    <SupportGridItem
                      title="How to create a virtual human being on Virtual Dyno Hub?"
                      content="To create a virtual human being on Virtual Dyno Hub, navigate to the 'Virtual Humans' page and click on the 'Create Virtual Human' button. Fill in the required information, such as appearance, behavior, and language settings, then click on 'Create'."
                    />
                </Grid>
            </Container>
        </Fragment>
      </Box>
    </ThemeProvider>
  );
}
