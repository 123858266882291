import React from "react";
import { ButtonBase, CardContent, Typography, Grid } from "@mui/material";

const styles = {
  icon: {
    fontSize: "20px",
    color: "#ffffff",
  },
  title: {
    fontSize: "16px",
    marginLeft: "10px",
    color: "#ffffff",
  },
  card: {
    borderRadius: 12,
    boxShadow:
      "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
    margin: "20px",
    width: "200px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#3f3f3f",
  },
  cardContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexGrow: 1,
    flex: 1,
  },
};

const FolderCard = ({ folder, icon }) => {
  const handleFolderRedirect = () => {
    console.log(`Navigating to folder: ${folder.name}`);
  };

  return (
    <ButtonBase
      onClick={handleFolderRedirect}
      style={styles.card}
      key={folder.name}
      sx={{ maxWidth: 345, marginTop: "50px" }}
    >
      <CardContent style={styles.cardContent}>
        <Grid container alignItems="center">
          <Grid item>
            {React.cloneElement(icon, { style: styles.icon })}
          </Grid>
          <Grid item>
            <Typography style={styles.title} component="div">
              {folder.name}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </ButtonBase>
  );
};

export default FolderCard;
