import React, { useState } from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Hidden from "@mui/material/Hidden";
import { useTheme } from "@mui/material/styles";
import { alpha } from "@mui/system";

import SettingMenuButton from "./SettingMenuButton";
import NotificationButton from "./Notification/NotificationButton";

import { getInitials } from "../utils/CardUtils";

import logo from "../assets/3d_logo.jpeg";

const pages = [
    { name: "Project", url: "/project" },
    { name: "Market", url: "/marketplace" },
    { name: "Support", url: "/support" },
];
const settings = ["Account", "Dashboard", "Logout"];

const styles = {
    logo: {
        height: 40,
        marginRight: "1rem",
        display: { xs: "none", md: "flex" },
        marginLeft: "30px",
    },
    appbar: {
        zIndex: (theme) => theme.zIndex.drawer + 1,
        bgcolor: "#101010",
    },
    menu: {
        display: { xs: "block", md: "none" },
    },
    page_name : {
        mr: 2,
        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
        fontWeight: 'bold',
        color: "inherit",
        textDecoration: "none",
    },
};

export default function NavBar({ handleError }) {
    const theme = useTheme();
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
            <AppBar
                position="sticky"
                sx={styles.appbar}
            >
                <Toolbar disableGutters>
                    <Hidden smDown>
                        {/* Logo */}
                        <img
                            src={logo}
                            alt="Hub"
                            style={styles.logo}
                        />

                        {/* Website name */}
                        <Typography sx={{ 
                            color: 'white', 
                            marginLeft: theme.spacing(1), 
                            display: { xs: "none", md: "flex" }, 
                            alignSelf: 'center', 
                            fontSize: '1.25rem', 
                            fontWeight: 600,
                            marginRight: theme.spacing(7),
                        }}>
                            Dyno Hub
                        </Typography>

                        {/* Add a line with color #333333 to separate the logo and the search bar */}
                        <Box
                            sx={{
                                bgcolor: "#333333",
                                width: "1px",
                                height: "40px",
                                alignSelf: "center",
                            }}
                        />
                    </Hidden>

                    <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={styles.menu}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                                    <Typography
                                        textAlign="center"
                                        component="a"
                                        href={page.url}
                                        sx={styles.page_name}
                                    >
                                        {page.name}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>

                    <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                        {pages.map((page) => (
                            <Button
                                key={page.name}
                                component="a"
                                href={page.url}
                                onClick={handleCloseNavMenu}
                                sx={{ 
                                    color: "white", 
                                    display: "block",
                                    width: "20%",
                                    fontWeight: 550,
                                    textAlign: "center",
                                    fontFamily: 'Inter, Helvetica, Arial, sans-serif',
                                    '&:hover': {
                                        backgroundColor: alpha(theme.palette.common.white, 0.25),
                                        transition: 'background-color 0.3s ease-in-out'
                                    }
                                }}
                            >
                                {page.name}
                            </Button>
                        ))}
                    </Box>

                    {/* Wrap the search bar and user settings in a Box */}
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexGrow: 1,
                            alignItems: "center",
                        }}
                    >

                        {/* Add the search bar */}
                        <Box
                            sx={{
                                position: "relative",
                                borderRadius: theme.shape.borderRadius,
                                backgroundColor: alpha(theme.palette.common.white, 0.15),
                                "&:hover": {
                                    backgroundColor: alpha(theme.palette.common.white, 0.25),
                                },
                                marginRight: 2,
                                marginLeft: 0,
                                width: "100%",
                                [theme.breakpoints.up("sm")]: {
                                    marginLeft: theme.spacing(1),
                                    width: "auto",
                                },
                            }}
                        >
                            <SearchIcon
                                sx={{
                                    padding: theme.spacing(0, 2),
                                    height: "100%",
                                    position: "absolute",
                                    pointerEvents: "none",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            />
                            <InputBase
                                placeholder="Search…"
                                sx={{
                                    color: "inherit",
                                    "& .MuiInputBase-input": {
                                        padding: theme.spacing(1, 1, 1, 0),
                                        // vertical padding + font size from searchIcon
                                        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
                                        transition: theme.transitions.create("width"),
                                        width: "100%",
                                        [theme.breakpoints.up("sm")]: {
                                            width: "70ch", 
                                            "&:focus": {
                                                width: "80ch", 
                                            },
                                        },
                                    },
                                }}
                            />
                        </Box>
                    
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                flexGrow: 1,
                            }}
                        >
                            {/* Add the notifications button */}
                            <Hidden smDown>
                                <NotificationButton handleError={handleError} />
                            </Hidden>

                            { /* Add the user settings button */ }
                            <Box sx={{ flexGrow: 0 }} marginRight={"30px"}>
                                <Tooltip title="Open settings">
                                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                        <Avatar>
                                            {getInitials(localStorage.getItem("firstName"))}
                                        </Avatar>
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{ mt: "45px" }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                {settings.map((setting) => (
                                    <SettingMenuButton
                                        key={setting}
                                        setting={setting}
                                        action={handleCloseUserMenu}
                                        onError={handleError}
                                    />
                                ))}
                                </Menu>
                            </Box>
                        </Box>
                    </Box>
            </Toolbar>
        </AppBar>
    );
}
