import React, { useState, useEffect } from "react";

import InfiniteScroll from "react-infinite-scroll-component";

import SectionHeader from "../../components/SectionHeader";
import ProjectRow from "../../components/ProjectRow";

import pastProjectsData from "./mockdata/PastProjects";


async function fetchPastProjects(page) {
  // Fetch data from the server or data source based on the page number
  // Return the fetched data
  return pastProjectsData; // Placeholder, replace with actual data
}

const Projects = () => {
    const [projects, setProjects] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const [anchorEl, setAnchorEl] = useState([]);

    // Fetch initial data on component mount
    useEffect(() => {
        fetchMoreData();
    }, []);

    const handleMenuClick = (event, index) => {
        const newAnchorEl = [...anchorEl];
        newAnchorEl[index] = event.currentTarget;
        setAnchorEl(newAnchorEl);
    };

    const handleMenuClose = (index) => {
        const newAnchorEl = [...anchorEl];
        newAnchorEl[index] = null;
        setAnchorEl(newAnchorEl);
    };

    const fetchMoreData = async () => {
        // Fetch data from the server or data source
        const newProjects = await fetchPastProjects(page);

        // If no more data is available, set hasMore to false
        if (newProjects.length === 0) {
          setHasMore(false);
          return;
        }

        // Update the state with the new projects
        setProjects((prevProjects) => [...prevProjects, ...newProjects]);

        // Increment the page number for the next fetch
        setPage((prevPage) => prevPage + 1);
    };

    return (
        <div>
          <SectionHeader title="Projects" />
          <InfiniteScroll
            dataLength={projects.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<h4>Loading...</h4>}
            endMessage={<p style={{ textAlign: "center" }}>No more projects</p>}
            style={{ overflow: "hidden", width: "100%" }}
          >
            {projects.map((project, index) => (
              ProjectRow(project, index, handleMenuClick, handleMenuClose, anchorEl)
            ))}
          </InfiniteScroll>
        </div>
    );
};

export default Projects;