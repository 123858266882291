const handleAlertError = (setAlertMessage, setShowAlert, errorComponent) => {
    setAlertMessage(errorComponent);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(true);
    }, 3000); // Hide the alert after 3 seconds
}

export {
    handleAlertError
};