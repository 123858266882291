import React, { useState, useEffect } from "react";

import { Stack, Hidden } from "@mui/material";

import NavBar from "../../components/NavBar";
import VideoContainer from "./components/VideoContainer";
import MarketplaceContent from "./MarketplaceContent";
import Sidebar from "./components/Sidebar";
import { handleAlertError } from "../../utils/AlertUtils";

import {
    getMarketplaceFeaturedApps,
    getMarketplaceTrendingApps,
} from "../../service/marketplace";

import { featuredDynos, popularDynos } from "./mockdata/Listable";

const sidebarWidth = 250;

const styles = {
    container: {
        paddingTop: "30px",
        paddingBottom: "30px",
        flexGrow: 1,
        marginLeft: `calc(${sidebarWidth}px + 30px)`,
        paddingRight: "0",
        maxWidth: `calc(100% - ${sidebarWidth}px - 100px)`,
    },
    container_sm: {
        paddingTop: "30px",
        paddingBottom: "30px",
        flexGrow: 1,
        marginLeft: "30px",
        paddingRight: "0",
        maxWidth: `calc(100% - 60px)`,
    },
    card: {
        borderRadius: 12,
        boxShadow:
            "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
        margin: "20px",
        width: "300px",
    },
    flexContainer: {
        display: "flex",
    },
    sidebar: {
        width: sidebarWidth,
    },
};

const Marketplace = () => {
    const [alertMessage, setAlertMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);

    const [featuredApps, setFeaturedApps] = useState([]);
    const [trendingApps, setTrendingApps] = useState([]);

    useEffect(() => {
        const token = localStorage.getItem("accessToken");
        if (!token) {
            handleAlertError(
                setAlertMessage,
                setShowAlert,
                "You must be logged in to view this page."
            );

            setTimeout(() => {
                window.location.href = "/login";
            }, 2000);
        }

        getMarketplaceTrendingApps()
            .then((response) => {
                if (response.status === 200) {
                    const data = response.data;
                    console.log(data);
                    setTrendingApps(data.items);
                } else {
                    setTrendingApps(popularDynos);
                    handleAlertError(
                        setAlertMessage,
                        setShowAlert,
                        "There was an error getting the marketplace list."
                    );
                }
            })
            .catch(() => {
                handleAlertError(
                    setAlertMessage,
                    setShowAlert,
                    "There was an error getting the marketplace list."
                );
            });

        getMarketplaceFeaturedApps()
            .then((response) => {
                if (response.status === 200) {
                    const data = response.data;
                    console.log(data);
                    setFeaturedApps(data.items);
                } else {
                    setFeaturedApps(featuredDynos);
                    handleAlertError(
                        setAlertMessage,
                        setShowAlert,
                        "There was an error getting the marketplace list."
                    );
                }
            })
            .catch(() => {
                handleAlertError(
                    setAlertMessage,
                    setShowAlert,
                    "There was an error getting the marketplace list."
                );
            });
    }, []);

    return (
        <>
            <NavBar
                handleError={(errorComponent) =>
                    handleAlertError(
                        setAlertMessage,
                        setShowAlert,
                        errorComponent
                    )
                }
            />
            <Hidden smDown>
                <div style={styles.flexContainer}>
                    <Sidebar width={styles.sidebar.width} />
                    <VideoContainer marginLeft={styles.sidebar.width} />
                </div>
                <MarketplaceContent
                    featuredApps={featuredApps}
                    trendingApps={trendingApps}
                    containerStyle={styles.container}
                    setShowAlert={setShowAlert}
                    setAlertMessage={setAlertMessage}
                />
            </Hidden>
            <Hidden mdUp>
                <VideoContainer marginLeft={0} />
                <MarketplaceContent
                    featuredApps={featuredApps}
                    trendingApps={trendingApps}
                    containerStyle={styles.container_sm}
                    setShowAlert={setShowAlert}
                    setAlertMessage={setAlertMessage}
                />
            </Hidden>
            {showAlert && (
                <Stack
                    spacing={2}
                    sx={{
                        width: "50%",
                        position: "fixed",
                        bottom: 8,
                        right: 8,
                        zIndex: 2000,
                        color: "white",
                    }}
                >
                    {alertMessage}
                </Stack>
            )}
        </>
    );
};

export default Marketplace;
