import React, { Fragment, useEffect, useState } from "react";

import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Hidden from "@mui/material/Hidden";
import Cookies from "js-cookie";

import Folders from "./Folders";
import Projects from "./Projects";
import RecentProjects from "./RecentProjects";

import Sidebar from "./components/Sidebar";
import NavBar from "../../components/NavBar";
import {handleAlertError} from "../../utils/AlertUtils";

const styles = {
    container: {
      paddingTop: "30px", 
      paddingBottom: "30px",
      backgroundColor: "#1d1d1d",
      marginLeft: { sm: "250px", xs: "0px" },
    },
    title: {
      fontSize: "40px",
      fontWeight: "700",
      lineHeight: "54px",
      letterSpacing: "-0.03em",
      textAlign: "left",
      marginBottom: "30px", 
      color: "#ffffff",
    },
    card: {
      borderRadius: 12, 
      boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
      margin: "20px",
    },
    cardMedia: {
      paddingTop: "56.25%"
    },
    cardContent: {
      flexGrow: 1,
    },
    button: {
      color: "#007aff", 
      borderColor: "#007aff" 
    },
    description: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      margin: "10px",
      color: "#ffffff",
    },
    cardContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      justifyContent: "space-between",
      overflowX: "auto"
    },
}

export default function AppView() {
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    useEffect(() => {
        const user_info = Cookies.get("user_info");
        if (user_info) {
          const userInfoObject = JSON.parse(user_info);
  
          localStorage.setItem("id", userInfoObject.id);
          localStorage.setItem("username", userInfoObject.username);
          localStorage.setItem("firstName", userInfoObject.first_name);
          localStorage.setItem("lastName", userInfoObject.last_name);
          localStorage.setItem("email", userInfoObject.email);
          localStorage.setItem("profile", userInfoObject.profile);
          localStorage.setItem("accessToken", userInfoObject.access_token);
          localStorage.setItem("refreshToken", userInfoObject.refresh_token);
          Cookies.remove("user_info");
        }
    }, []);

    return (
      <Fragment>
        <NavBar handleError={(errorComponent) => handleAlertError(setAlertMessage, setShowAlert, errorComponent)} />
        <Hidden smDown>
          <Sidebar />
        </Hidden>
        {showAlert && (
          <Stack
            spacing={2}
            sx={{
              width: "50%",
              position: "fixed",
              bottom: 8,
              right: 8,
              zIndex: 2000,
            }}
          >
            {alertMessage}
          </Stack>        
        )}
        <Container style={styles.container}>
          <Typography component="h1" variant="h3" style={styles.title}>
            { localStorage.getItem("username") == null? `Welcome to Project Hub!`: `Welcome to Project Hub, ${localStorage.getItem("firstName")}!`}
          </Typography>
          <RecentProjects />
          <Folders />
          <Projects />
        </Container>
      </Fragment>
    );
}