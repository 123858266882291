import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import SceneCanvas from '../../components/Canvas';
import FileSelector from '../../components/FileSelector';
import NavBar from '../../components/NavBar';

import CodeEditor from './CodeEditor';

import './AdvancedBuilder.css';

function AdvancedBuilder() {
    const [selectedFolder, setSelectedFolder] = useState(null);

    const handleFolderChange = (folderPath) => {
        setSelectedFolder(folderPath);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <NavBar />
            <Grid container spacing={2} className="advanced-builder-grid">
                <Grid item xs={12} md={6} className="canvas-grid-item">
                    <div className="canvas-container">
                        <SceneCanvas />
                    </div>
                </Grid>
                <Grid item xs={12} md={6} className="code-editor-grid-item">
                    <div className="code-editor-container">
                        <CodeEditor />
                    </div>
                </Grid>
                <Grid item xs={12} className="file-selector-grid-item">
                    <div className="file-selector-container">
                        <FileSelector onFolderChange={handleFolderChange} />
                        {selectedFolder && <p>Selected folder: {selectedFolder}</p>}
                    </div>
                </Grid>
            </Grid>
        </Box>
    );
}

export default AdvancedBuilder;
