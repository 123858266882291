import React, { useState } from "react";
import * as THREE from "three";
import { Extrude } from "@react-three/drei";


const extrudeSettings = { steps: 2, depth: 10, bevelEnabled: false };
const SIDE = 10;

export default function Block(props) {
    const [hovered, hover] = useState(false)
    const [clicked, click] = useState(false)

    const shape = React.useMemo(() => {
      const _shape = new THREE.Shape();
  
      _shape.moveTo(0, 0);
      _shape.lineTo(SIDE, 0);
      _shape.lineTo(SIDE, SIDE * 2);
      _shape.lineTo(0, SIDE * 2);
      _shape.lineTo(0, SIDE * 3);
      _shape.lineTo(-SIDE, SIDE * 3);
      _shape.lineTo(-SIDE, SIDE);
      _shape.lineTo(0, SIDE);
  
      return _shape;
    }, []);

    const ChangeColor = (hover, isHovered) => {
        hover(isHovered);
    }
  
    return (
      <>
        <Extrude args={[shape, extrudeSettings]} {...props}>
          <meshStandardMaterial 
            flatShading
            attenuationTint="#fff"
            attenuationDistance={0}
            onClick={(event) => click(!clicked)}
            onPointerOver={(event) => ChangeColor(hover, true)}
            onPointerOut={(event) => ChangeColor(hover, false)}
            color={hovered ? 'hotpink' : 'orange'} 
          />
        </Extrude>
      </>
    );
  }