import React, { useState } from "react";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import Box from "@mui/material/Box";
import NotificationsIcon from "@mui/icons-material/Notifications";

import NotificationMenuButton from "./NotificationMenuButton";

import notifications from "./mockdata/Notifications";

const NotificationButton = ({ handleError }) => {
    const [anchorElNotif, setAnchorElNotif] = useState(null);

    // handle open notification menu
    const handleOpenNotifMenu = (event) => {
        setAnchorElNotif(event.currentTarget);
    };

    // handle close notification menu
    const handleCloseNotifMenu = () => {
        setAnchorElNotif(null);
    };

    return (
        <Box sx={{ flexGrow: 0 }} marginRight={"30px"} marginTop={"5px"}>
            <Tooltip title="Notifications">
                <IconButton onClick={handleOpenNotifMenu} sx={{ p: 0 }}>
                    <NotificationsIcon  sx={{ color: "#ffffff", fontSize: 28, display: {xs: "none", md: "flex" }}} />
                </IconButton>
            </Tooltip>
            <Menu
                sx={{ mt: "45px", '& .MuiPaper-root': { backgroundColor: "#343541" } }}
                id="notif-menu-appbar"
                anchorEl={anchorElNotif}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={Boolean(anchorElNotif)}
                onClose={handleCloseNotifMenu}
            >
                {notifications.map((notification) => (
                    <NotificationMenuButton
                        key={notification}
                        notification={notification}
                        onError={handleError}
                    />
                ))}
            </Menu>
        </Box>
    );
}

export default NotificationButton;