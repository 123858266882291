const pastProjectsData = [
    {
      name: "Past Project 1",
      description: "This is a past project.",
      collaborators: ["Michael Zhou", "Brandon Liu"],
      createdTime: "5/1/2023",
    },
    {
      name: "Past Project 2",
      description: "This is another past project.",
      collaborators: ["Michael Zhou"],
    }
];

export default pastProjectsData;