import React from 'react';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import HomeIcon from '@mui/icons-material/Home';
import SchoolIcon from '@mui/icons-material/School';
import StorefrontIcon from '@mui/icons-material/Storefront';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import StarOutlineIcon from '@mui/icons-material/StarOutline';

const IconSelector = (iconDescription) => {
    switch (iconDescription.toLowerCase()) {
        case 'folder':
            return <FolderOpenIcon fontSize="large" />;
        case 'work':
            return <WorkOutlineIcon fontSize="large" />;
        case 'home':
            return <HomeIcon fontSize="large" />;
        case 'school':
            return <SchoolIcon fontSize="large" />;
        case 'business':
            return <StorefrontIcon fontSize="large" />;
        case 'favorite':
            return <BookmarkBorderIcon fontSize="large" />;
        case 'starred':
            return <StarOutlineIcon fontSize="large" />;
        default:
            return <FolderOpenIcon fontSize="large" />;
    }
};

export default IconSelector;
